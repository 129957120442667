import React, { useState } from 'react';
import RightSidebar from './WorkshopNotes/RightSidebar';
import ButtonGrid from './WorkshopNotes/ButtonGrid';

const WorkshopNotes = () => {
  const rows = [
    ['1^', '2^', '3^', '4^', '5^', '6^', '7^', '1^^', '.'],
    ['1', '2', '3', '4', '5', '6', '7', '1^', '0'],
    ['^1', '^2', '^3', '^4', '^5', '^6', '^7', '1', '|']
  ];

  const [title, setTitle] = useState('');
  const [inputText, setInputText] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = () => {
    console.log('Title:', title);
    console.log('Input Text:', inputText);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', height: '100vh' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' }}>
        <h1>Workshop Notes</h1>
        <div>
          <textarea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            rows={4}
            cols={50}
            placeholder="Enter your notes here..."
            style={{ marginBottom: '10px' }}
          />
        </div>
        <ButtonGrid rows={rows} />
        <div style={{ marginTop: '20px' }}>
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ marginLeft: '5px' }}
          />
        </div>
        <button onClick={handleSubmit} style={{ marginTop: '10px' }}>Submit</button>
        {/* Display the selected option */}
        <label>{selectedOption && `Selected Option: ${selectedOption}`}</label>
      </div>
      {/* Pass selectedOption and handleOptionSelect as props to RightSidebar */}
      <RightSidebar selectedOption={selectedOption} onOptionSelect={handleOptionSelect} />
    </div>
  );
};

export default WorkshopNotes;
