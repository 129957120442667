// GenshinParty.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const GenshinParty = () => {
  const navigate = useNavigate();
  const [numbers, setNumbers] = useState([null, null, null, null]);

  const goToHome = () => {
    navigate('/');
  };

  const goToWorkshopNotes = () => {
    navigate('/workshop-notes');
  };

  const generateRandomNumbers = () => {
    const uniqueRandomNumbers = [];
    while (uniqueRandomNumbers.length < 4) {
      const randomNumber = Math.floor(Math.random() * 100);
      if (!uniqueRandomNumbers.includes(randomNumber)) {
        uniqueRandomNumbers.push(randomNumber);
      }
    }
    setNumbers(uniqueRandomNumbers);
  };

  return (
    <div>
      <h1>Party Generator</h1>
      <div style={containerStyle}>
        {numbers.map((number, index) => (
          <div key={index} style={rectangleStyle}>
            {number !== null ? number : 'Click button to generate'}
          </div>
        ))}
      </div>
      <br />
      <button onClick={generateRandomNumbers}>Generate Random Numbers</button>
      <button onClick={goToHome}>Go to Home</button>
      <button onClick={goToWorkshopNotes}>Go to Workshop Notes</button>
    </div>
  );
};

const containerStyle = {
  display: 'flex',
};

const rectangleStyle = {
  width: '120px',
  height: '180px',
  border: '1px solid black',
  textAlign: 'center',
  margin: '0 10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default GenshinParty;
