import React from 'react';

const ButtonGrid = ({ rows }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} style={{ display: 'flex' }}>
          {row.map((label, index) => (
            <button key={index} style={{ width: index === 8 ? '70px' : '50px', height: '50px', margin: '5px', position: 'relative' }}>
              {label.includes('^') && <span style={{
                position: 'absolute',
                fontSize: '12px',
                left: label.indexOf('^') === 0 ? '22px' : 'auto',
                right: label.indexOf('^') === 1 ? '22px' : 'auto',
                top: label.indexOf('^') === 1 ? 'calc(40% - 5px)' : 'calc(60% + 5px)',
                transform: 'translateY(-50%)' }}>•</span>}
              {label.includes('^^') && <span style={{
                position: 'absolute',
                fontSize: '12px',
                left: '50%',
                top: '1px',
                transform: 'translateX(-50%)' }}>•</span>}
              {label.replace('^', '').replace('^^', '')}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ButtonGrid;
