import React from 'react';

const RightSidebar = ({ selectedOption, onOptionSelect }) => {
  const options = ['2/2', '2/4', '3/4', '4/4', '5/4', '5/8', '3/8', '6/8', '9/8', '12/8'];

  // Array of musical notes
  const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B'];

  return (
    <div style={{ width: '30%', padding: '20px', backgroundColor: '#f0f0f0', textAlign: 'center' }}>
      <div style={{ marginBottom: '20px' }}>
        <label>{selectedOption && `Selected Option: ${selectedOption}`}</label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        {options.map((option, index) => (
          <button key={index} onClick={() => onOptionSelect(option)} style={{ marginRight: '5px' }}>{option}</button>
        ))}
      </div>
      <div style={{ marginBottom: '20px' }}>
        <hr style={{ width: '100%', margin: '0' }} />
      </div>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
        <label>Buttons:</label>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '5px', justifyContent: 'center' }}>
        {/* Render buttons with musical notes */}
        {notes.map((note, index) => (
          <button key={index} style={{ width: '50px', height: '50px' }}>{note}</button>
        ))}
      </div>
    </div>
  );
};

export default RightSidebar;
