// src/Logout.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Perform logout logic, such as clearing user session data
    // For example:
    // localStorage.removeItem('userToken');

    // Redirect to login page
    navigate('/');
  }, [navigate]);

  return null; // This component doesn't need to render anything
};

export default Logout;
