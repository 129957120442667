// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LoginPage from './LoginPage';
import Home from './Home';
import WorkshopNotes from './WorkshopNotes';
import GenshinParty from './GenshinParty';
import Sidebar from './Sidebar';
import User from './User';
import Logout from './Logout'; // Import the Logout component

function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

function MainLayout() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/';

  return (
    <div style={{ display: 'flex' }}>
      {!isLoginPage && <Sidebar />}
      <div style={{ paddingLeft: isLoginPage ? '0' : '20px', paddingRight: '20px', flexGrow: 1 }}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/user" element={<User />} />
          <Route path="/workshop-notes" element={<WorkshopNotes />} />
          <Route path="/genshin-party" element={<GenshinParty />} />
          <Route path="/logout" element={<Logout />} /> {/* Add logout route */}
        </Routes>
      </div>
    </div>
  );
}

export default App;
