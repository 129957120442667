import React, { useState, useEffect } from 'react';
import './Home.css';

const Home = () => {
  const [message, setMessage] = useState('');
  const [szBackendStatus, setszBackendStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:8080/hello');
        const data = await response.json();
        console.log(data);
        setMessage(data.data[0].fmessage);
        setszBackendStatus('Backend Online');
      } catch (error) {
        console.log('Error fetching data:', error);
        setMessage('No message.');
        setszBackendStatus('Backend Offline. Do a ".\\run.bat" on lateral to run it.');
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="content">
        <h1>Home</h1>
        <label>{message}</label>
      </div>
      <div className="status-bar">
        <label className={szBackendStatus === 'Backend Online' ? 'status-online' : 'status-offline'}>
          {szBackendStatus}
        </label>
      </div>
    </div>
  );
};

export default Home;
