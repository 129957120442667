// src/EditUserModal.js
import React from 'react';

const EditUserModal = ({ isOpen, data, fields, onChange, onConfirm, onCancel }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit</h5>
            <button type="button" className="close" onClick={onCancel}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              {fields.map((field) => (
                <div className="form-group" key={field.accessor}>
                  <label>{field.label}</label>
                  <input
                    type={field.type || 'text'}
                    className="form-control"
                    value={data[field.accessor]}
                    readOnly={field.readOnly}
                    onChange={(e) => onChange({ ...data, [field.accessor]: e.target.value })}
                  />
                </div>
              ))}
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={onConfirm}>Update</button>
            <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
