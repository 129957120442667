// Sidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div style={{ 
      height: '100vh',
      width: isCollapsed ? '50px' : '200px', // Adjust width based on collapsed state
      backgroundColor: '#f0f0f0',
      overflowY: 'auto',
      transition: 'width 0.3s', // Add transition effect
    }}>
      {/* Collapsible button */}
      <button onClick={toggleSidebar} style={{ display: 'block', margin: '10px' }}>
        {isCollapsed ? '»' : '«'}
      </button>

      {/* Sidebar content */}
      {!isCollapsed && (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li><Link to="/logout">Logout</Link></li>
          <hr></hr>
          <li><Link to="/home">Home</Link></li>
          <hr></hr>
          <li><Link to="/home">Package</Link></li>
          <li><Link to="/home">Group</Link></li>
          <li><Link to="/home">Subgroup</Link></li>
          <li><Link to="/user">User</Link></li>
          <hr></hr>
          <li><Link to="/workshop-notes">Workshop Notes</Link></li>
          <li><Link to="/genshin-party">Party Generator</Link></li>
          <hr></hr>
        </ul>
      )}
    </div>
  );
};

export default Sidebar;
