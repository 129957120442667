// src/User.js
import React, { useEffect, useState } from 'react';
import DataTable from './DataTable';
import ConfirmationModal from './ConfirmationModal';
import EditUserModal from './EditUserModal';

const User = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editedUserData, setEditedUserData] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmCallback, setConfirmCallback] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('http://localhost:8080/user/list');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setUsers(data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setEditedUserData(user);
    setIsEditModalOpen(true);
  };

  const handleUpdateConfirmation = async () => {
    try {
      const response = await fetch('http://localhost:8080/user/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedUserData),
      });

      if (!response.ok) {
        throw new Error('Failed to update user');
      }

      // Update the user in the state
      setUsers(users.map((user) => (user.fuser_id === editedUserData.fuser_id ? editedUserData : user)));
    } catch (error) {
      console.error('Error updating user:', error);
    }

    setIsEditModalOpen(false);
    setConfirmationMessage('');
    setConfirmCallback(null);
  };

  const handleDelete = (user) => {
    setSelectedUser(user);
    setConfirmationMessage(`Are you sure you want to delete user ${user.fuser_name}?`);
    setConfirmCallback(() => confirmDelete);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    if (confirmCallback) {
      await confirmCallback(selectedUser);
    }
    setIsDeleteModalOpen(false);
    setConfirmationMessage('');
    setConfirmCallback(null);
  };

  const confirmDelete = async (user) => {
    try {
      const response = await fetch('http://localhost:8080/user/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fuser_id: user.fuser_id }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete user');
      }

      // Remove the user from the state
      setUsers(users.filter((u) => u.fuser_id !== user.fuser_id));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    setIsDeleteModalOpen(false);
    setSelectedUser(null);
    setEditedUserData({});
    setConfirmationMessage('');
    setConfirmCallback(null);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'fuser_id',
      },
      {
        Header: 'Username',
        accessor: 'fuser_name',
      },
      {
        Header: 'Password',
        accessor: 'fuser_password',
      },
      {
        Header: 'Email',
        accessor: 'fuser_email',
      },
      {
        Header: 'Phone Number',
        accessor: 'fuser_phone',
      },
      {
        Header: 'Last Login at',
        accessor: 'fuser_last_login_at',
      },
      {
        Header: 'Created at',
        accessor: 'fuser_created_at',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div>
            <button className="btn btn-primary mr-2" onClick={() => handleEdit(row.original)}>Edit</button>
            <button className="btn btn-danger" onClick={() => handleDelete(row.original)}>Delete</button>
          </div>
        ),
      },
    ],
    [users]
  );

  const editUserFields = [
    { label: 'ID', accessor: 'fuser_id', readOnly: true },
    { label: 'Username', accessor: 'fuser_name' },
    { label: 'Password', accessor: 'fuser_password', type: 'password' },
    { label: 'Email', accessor: 'fuser_email' },
    { label: 'Phone Number', accessor: 'fuser_phone' },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>User List</h1>
      <DataTable columns={columns} data={users} />

      <EditUserModal
        isOpen={isEditModalOpen}
        data={editedUserData}
        fields={editUserFields}
        onChange={setEditedUserData}
        onConfirm={() => setConfirmationMessage('Are you sure you want to update this user?') || setConfirmCallback(handleUpdateConfirmation)}
        onCancel={closeModal}
      />

      <ConfirmationModal
        isOpen={isDeleteModalOpen || (confirmationMessage !== '' && confirmCallback === handleUpdateConfirmation)}
        message={confirmationMessage}
        onConfirm={confirmCallback}
        onCancel={closeModal}
      />
    </div>
  );
};

export default User;
